import logo from './logo.svg';
import './App.css';
import TestingFrom from './pages/from';

function App() {
  return (
    <div className="App">
   <TestingFrom/>
    </div>
  );
}

export default App;
