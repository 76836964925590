import React, { useState } from 'react';
import { data } from "../utils/data";

const TestingFrom = () => {
  const [pageData, setPageData] = useState(data);
  const [userData, setUserData] = useState({
    Name: "",
    ContactNumber: "",
    VehicleNumber: "",
    BillNumber: ""
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!userData.Name.trim()) {
      errors.Name = 'Name is required';
    }
    if (!userData.ContactNumber.trim()) {
      errors.ContactNumber = 'Contact Number is required';
    } else if (!/^\d{10}$/.test(userData.ContactNumber)) {
      errors.ContactNumber = 'Invalid Contact Number';
    }
    if (!userData.VehicleNumber.trim()) {
      errors.VehicleNumber = 'Vehicle Number is required';
    }
    if (!userData.BillNumber.trim()) {
      errors.BillNumber = 'Bill Number is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append('Name', userData.Name);
      formData.append('ContactNumber', userData.ContactNumber);
      formData.append('VehicleNumber', userData.VehicleNumber);
      formData.append('BillNumber', userData.BillNumber);
      if (selectedFile) {
        formData.append('file', selectedFile);
      }

      console.log(userData)
      try {
        const response = await fetch('https://api.formbucket.com/f/c2K3QTQ', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          console.log('Form submitted successfully');
        } else {
          console.error('Form submission failed');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="form-box">
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <img className="mb-0" src="https://luckydrawbucket.s3.amazonaws.com/company/bpcl_logo.jpg" alt="BPCL Logo" />
      </div>
      <div className='py-0'>
        <h1 className='py-0 text-center title1'>{pageData.title1}</h1>
        <h3 className='text-center fs-4 title2'>{pageData.tilte2}</h3>
        <h5 className='text-center small title3'>{pageData.tilte3}</h5>
      </div>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="form-group">
          <label htmlFor="name">Name / नाम</label>
          <input className="form-control" id="name" type="text" name="Name" value={userData.Name} onChange={handleChange} />
          {errors.Name && <small className="text-danger">{errors.Name}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="contactNumber">Contact Number / फ़ोन नंबर</label>
          <input className="form-control" id="contactNumber" type="text" name="ContactNumber" value={userData.ContactNumber} onChange={handleChange} />
          {errors.ContactNumber && <small className="text-danger">{errors.ContactNumber}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="vehicleNumber">Vehicle Number / गाडी नंबर</label>
          <input className="form-control" id="vehicleNumber" type="text" name="VehicleNumber" value={userData.VehicleNumber} onChange={handleChange} />
          {errors.VehicleNumber && <small className="text-danger">{errors.VehicleNumber}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="billNumber">Bill Number / बिल नंबर</label>
          <input className="form-control" id="billNumber" type="text" name="BillNumber" value={userData.BillNumber} onChange={handleChange} />
          {errors.BillNumber && <small className="text-danger">{errors.BillNumber}</small>}
        </div>
        <div className="form-group ">
          <label htmlFor="formFileLg" className="form-label">Bill / बिल की फोटो</label>
          <input className="form-control" id="formFileLg" type="file" onChange={handleFileChange} />
        </div>
        <input className="btn btn-primary gap-2" type="submit" value="Submit" />
      </form>
      <p className="d-flex flex-column gap-2 py-3">{pageData.footer}</p>
    </div>
  );
};

export default TestingFrom;
